@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background: linear-gradient(230deg, rgba(93, 93, 93, 0.03) 0%, rgba(93, 93, 93, 0.03) 50%,rgba(78, 78, 78, 0.03) 50%, rgba(78, 78, 78, 0.03) 100%),linear-gradient(107deg, rgba(55, 55, 55, 0.01) 0%, rgba(55, 55, 55, 0.01) 50%,rgba(170, 170, 170, 0.01) 50%, rgba(170, 170, 170, 0.01) 100%),linear-gradient(278deg, rgba(16, 16, 16, 0.03) 0%, rgba(16, 16, 16, 0.03) 50%,rgba(24, 24, 24, 0.03) 50%, rgba(24, 24, 24, 0.03) 100%),linear-gradient(205deg, rgba(116, 116, 116, 0.03) 0%, rgba(116, 116, 116, 0.03) 50%,rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 100%),linear-gradient(150deg, rgba(5, 5, 5, 0.03) 0%, rgba(5, 5, 5, 0.03) 50%,rgba(80, 80, 80, 0.03) 50%, rgba(80, 80, 80, 0.03) 100%),linear-gradient(198deg, rgba(231, 231, 231, 0.03) 0%, rgba(231, 231, 231, 0.03) 50%,rgba(26, 26, 26, 0.03) 50%, rgba(26, 26, 26, 0.03) 100%),linear-gradient(278deg, rgba(89, 89, 89, 0.03) 0%, rgba(89, 89, 89, 0.03) 50%,rgba(26, 26, 26, 0.03) 50%, rgba(26, 26, 26, 0.03) 100%),linear-gradient(217deg, rgba(28, 28, 28, 0.03) 0%, rgba(28, 28, 28, 0.03) 50%,rgba(202, 202, 202, 0.03) 50%, rgba(202, 202, 202, 0.03) 100%),linear-gradient(129deg, rgba(23, 23, 23, 0.03) 0%, rgba(23, 23, 23, 0.03) 50%,rgba(244, 244, 244, 0.03) 50%, rgba(244, 244, 244, 0.03) 100%),linear-gradient(90deg, rgb(1, 64, 149),rgb(198, 5, 91));
}

.NavBar{
  background: #262626;
  padding: 1% 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
}

.NavBar a{
  color: MEDIUMSLATEBLUE;
  font-family: "Nunito, sans-serif";
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
}
.NavBar button{
  background: MEDIUMSLATEBLUE;
  color:white;
  border: none;
  border-radius: 20px;
  font-size: 1.5rem;
  padding: 0.2rem 0.9rem;
}

.NavBar:last-child{
  margin: 0 auto;
}

.form-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-wrapper form{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 5% 3% 1%;
  background: rgba(0, 0, 0, 0.2);
  color: white;
}
.form-wrapper form label{
  font-size: 1.5rem;
}
.form-wrapper form input{
  margin: 1% 0 6%;
  padding: 0.5rem;
  border-radius: 10px;
}
.form-wrapper form input:invalid,.form-wrapper form input:required{
  border: 1px solid red;
}
.form-wrapper form input:required::after{
  content: "*";
}
.form-wrapper form input:valid{
  border: 1px solid green;
}
.form-wrapper form input:focus{
  outline: none;
}
.form-wrapper form button{
  padding: 0.5rem 2.5rem;
  font-size: 1.7rem;
  margin: 10% auto;
  border: none;
  border-radius: 20px;
  background-color: MEDIUMSLATEBLUE;
  font-family: "Nunito, sans-serif";
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: white;
}
.form-wrapper form button:hover{
  background-color: SLATEBLUE;
}
.form-wrapper .MuiFormLabel-root.Mui-focused{
  color:SLATEBLUE;
}
.form-wrapper .MuiFormGroup-root{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form-wrapper p{
  font-size: 1.2rem;
}
.form-wrapper a{
  text-decoration: none;
  color: slateblue;
}

.auctionListWrapper{
  padding: 1% 5%;
  margin: 3% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
}

.Auctions {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 5%;
}

.AuctionItem {
  margin-bottom: 5%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #262626; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 73, 73); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
